import { createColumns, IColumnsGroup, ITableHeader } from "@/utils/ColumnsCreator";
import { CreatedFilters } from "@/services/filters/filters";
import { IFilter } from "@/types";
import { filterClass, SelectFilter } from "@/services/filters/filterClass";
import { computed } from "vue";
import { getActiveUserSearch, getAffiliateSearch } from "@/api/user";
import { getEmployeesOffersV2 } from "@/api/offers";
import { IOfferSearch } from "@/api/types/offers";
import { IUser } from "@/api/types/user";
import store from "@/store";
import { USER_ROLES } from "@/mappings/user-roles";
import { useWindowResize } from "@/utils/useWindowSize";

export const useMyTasks = () => {
    const headers: ITableHeader[] = [
        { text: 'ID', sortable: false, value: 'id', align: 'center', width: '50px' },
        { text: 'Тип', sortable: false, value: 'target', width: '70px' },
        { text: 'ID Объекта', sortable: false, value: 'target_id', width: '70px' },
        { text: 'Имя объекта', sortable: false, value: 'target_name' },
        { text: 'Автор', sortable: false, value: 'created_by' },
        { text: 'Исполнитель', sortable: false, value: 'assignee' },
        { text: 'Текст задачи', sortable: false, value: 'text', width: '400px' },
        { text: 'Дедлайн', sortable: false, value: 'date', align: 'right' },
        { text: 'Статус', sortable: false, value: 'status', width: '140px' },
        { text: 'Дата создания', sortable: false, value: 'created_at', align: 'right' },
        { text: 'Дата обновления', sortable: false, value: 'updated_at', align: 'right' },
        { text: 'Дата завершения', sortable: false, value: 'finished_at', align: 'right' },
        { text: '', sortable: false, value: 'actions', fixed: true, align: 'center', width: '55px' },
    ];

    const headersForTargetDetail: ITableHeader[] = [
        { text: 'ID', sortable: false, value: 'id', align: 'center', width: '50px' },
        { text: 'Автор', sortable: false, value: 'created_by', width: '90px' },
        { text: 'Исполнитель', sortable: false, value: 'assignee', width: '90px' },
        { text: 'Текст задачи', sortable: false, value: 'text', width: '300px' },
        { text: 'Дедлайн', sortable: false, value: 'date', align: 'right' },
        { text: 'Статус', sortable: false, value: 'status', width: '140px' },
        { text: '', sortable: false, value: 'actions', fixed: true, align: 'center', width: '55px' },
    ];

    const defaultColumns = ['id', 'target', 'target_id', 'target_name', 'created_by', 'assignee', 'text', 'date', 'status'];

    const getDefaultColumns = computed(() => {
        const { width } = useWindowResize();
        return width.value >= 768 ? defaultColumns : defaultColumns.filter(h => h !== 'target');
    });

    const columnsForTargetDetail = ['id', 'created_by', 'assignee', 'text', 'date', 'status'];

    const getColumns = (): IColumnsGroup[] => {
        return createColumns(headers, getDefaultColumns.value);
    };

    return { headers, headersForTargetDetail, getColumns, getDefaultColumns, columnsForTargetDetail };
};

export class TasksFilters extends CreatedFilters {
    constructor() {
        super();
    }

    async changeTargetFiltersOnInit(): Promise<void> {
        const filterTarget = this.filters.find(f => f.id === 'target');
        if (!filterTarget) return;
        filterTarget.callback!(filterTarget);

        const filterTargetId = this.filters.find(f => f.id === 'target_id');
        if (!filterTargetId) return;
        const selected = store.getters['GET_FILTERS_PARAMS_OBJECT'].target_id;

        if (selected === undefined) return;

        const getItems = async (value) => {
            const param = { [filterTargetId.key || 'query']: value };
            if (!filterTargetId.apiMethod) return;
            const res = await filterTargetId.apiMethod(param);
            this.getDataForSelect(filterTargetId, res);
        };

        const ids = Array.isArray(selected) ? selected : [selected];
        for (const id of ids) {
            await getItems(id);
        }

        filterTargetId.select = filterTargetId.items;
        this.updateStartUrl();
    }

    getFilters(): IFilter[] {
        const changeType = (filter?: SelectFilter) => {
            const filterTarget = this.filters.find(f => f.id === 'target_id');

            const defaultTargetFilter = {
                col: 2,
                id: 'target_id',
                label: 'Объект',
                disabled: true,
                select: [],
                items: [],
                tooltip: 'Сначала отфильтруйте задачи по типу',
                multiple: true,
            };

            const offerFilter = {
                errorText: 'Оффер не найден',
                itemValue: 'legacy_id',
                template: (item: IOfferSearch): string => item.legacy_id + ' ' + item.name,
                apiMethod: getEmployeesOffersV2,
                key: 'search',
            };

            const affiliateFilter = {
                errorText: 'Партнёр не найден',
                itemValue: 'id',
                template: (item: IUser): string => item.id + ' ' + item.first_name + ' ' + item.last_name,
                apiMethod: getAffiliateSearch,
                key: 'query',
            };

            if (!filterTarget) return;
            if (filter?.select === 'offer') {
                Object.assign(filterTarget, defaultTargetFilter, offerFilter);
            } else if (filter?.select === 'affiliate') {
                Object.assign(filterTarget, defaultTargetFilter, affiliateFilter);
            } else {
                Object.assign(filterTarget, defaultTargetFilter);
            }
            filterTarget.select = [];
            filterTarget.items = [];
            filterTarget.disabled = !filter?.select;
        };

        const user = store.getters['authModule/GET_USER'];
        const isAdmin = user?.roles?.includes(USER_ROLES.ADMIN);

        return [
            new filterClass.Select({
                col: 1,
                id: 'target',
                label: 'Тип',
                items: ['offer', 'affiliate'],
                translate: (type) => type === 'offer' ? 'Оффер' : 'Партнёр',
                clearable: true,
                callback: changeType,
                remove: changeType,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'target_id',
                label: 'Объект',
                itemValue: 'id',
                disabled: true,
                select: '',
                tooltip: 'Сначала отфильтруйте задачи по типу',
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'author',
                apiMethod: getActiveUserSearch,
                label: 'Автор',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                defaultValue: [],
                items: [],
                isHidden: !isAdmin,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'assignee',
                apiMethod: getActiveUserSearch,
                label: 'Исполнитель',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                defaultValue: [],
                items: [],
                isHidden: !isAdmin,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'date',
                label: 'Дедлайн',
                select: [],
                pickerType: 'date',
                clearable: true,
            }),
            new filterClass.Multiselect({
                col: 2,
                id: 'status',
                itemValue: 'value',
                itemText: 'text',
                label: 'Статус',
                select: ['to_do', 'expired'],
                items: [
                    { text: 'К выполнению', value: 'to_do' },
                    { text: 'Завершена', value: 'done' },
                    { text: 'Просрочена', value: 'expired' },
                ],
                template: (i) => i.text,
            }),
        ];
    }
}
