import { IParamsObject, IServerResponse } from "@/api/types";
import { AxiosResponse } from "axios";
import { axiosInstance } from "@/api/index";
import { API_TASKS_URL } from "@/configs/global";
import { IBulkCreateTask, ICreateTask, ITask, ITaskStatus } from "@/api/types/tasks";
import { FormattedItem } from "@/utils/CellsFromBff";

export async function getTasks(params: IParamsObject): Promise<IServerResponse<ITask[]>> {
    return axiosInstance.request<IServerResponse<ITask[]>>({
        method: 'GET',
        url: `${API_TASKS_URL}/employees/tasks`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ITask[]>>) => response.data);
}

export async function getTasksBff(params: IParamsObject): Promise<IServerResponse<FormattedItem<ITask>[]>> {
    return axiosInstance.request<IServerResponse<FormattedItem<ITask>[]>>({
        method: 'GET',
        url: `${API_TASKS_URL}/employees/tasks-list`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<FormattedItem<ITask>[]>>) => response.data);
}

export async function getOneTask(id: string): Promise<IServerResponse<ITask>> {
    return axiosInstance.request<IServerResponse<ITask>>({
        method: 'GET',
        url: `${API_TASKS_URL}/employees/tasks/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ITask>>) => response.data);
}

export async function deleteOneTask(id: string): Promise<IServerResponse<ITask>> {
    return axiosInstance.request<IServerResponse<ITask>>({
        method: 'DELETE',
        url: `${API_TASKS_URL}/employees/tasks/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ITask>>) => response.data);
}

export async function changeTaskStatus(id: number, status: ITaskStatus): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_TASKS_URL}/employees/tasks/${id}/status`,
        data: {
            status,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

export async function changeTask(id: number, data: { assignee?: number, text?: string, date?: string}): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_TASKS_URL}/employees/tasks/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

export async function createNewTask(data: ICreateTask): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_TASKS_URL}/employees/tasks`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

export async function bulkCreateNewTask(data: IBulkCreateTask): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_TASKS_URL}/employees/tasks/bulk`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}
