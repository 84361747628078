import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';
import { IFilter } from '@/types';

export class CheckboxFilter extends BaseFilter {
    itemText?: string;
    trueValue?: any;
    falseValue?: any;
    checkbox? = true;

    constructor(config: ConstructorType<CheckboxFilter, 'checkbox'>) {
        super(config);
        this.select = config.select ?? [];
        this.itemText = config.itemText;
        this.trueValue = config.trueValue;
        this.falseValue = config.falseValue;
    }

    clear(): void {
        this.select = Array.isArray(this.select) ? [] : 'false';
        if (this.isSubmitEmptySelect !== undefined) this.isSubmitEmptySelect = false;
    }

    parseQueryAndGetChanges(query: any, currentQueryKey: any): Record<string, any> {
        const queryValue = query[currentQueryKey];

        if (Array.isArray(this.select)) {
            if (Array.isArray(queryValue)) {
                const values = this.castQueryValueToType(queryValue);
                this.select = values;
                return { [this.id]: values };
            } else {
                this.select = [queryValue];
                return { [this.id]: queryValue };
            }
        }

        if (!this.itemText) {
            this.select = queryValue === 'true';
            return { [this.id]: this.select };
        }

        return { [this.id]: this.select };
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        return { [filter.id]: (Array.isArray(filter.select) && filter.select.length) ? filter.select[0] : filter.select };
    }
}
