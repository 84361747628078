import router from "@/router";

export const updateQueryString = (query: any): void => {
    router.replace({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
            throw error;
        }
    });
};

