import RouteCell from "@/components_v3/base/table/cells/RouteCell.vue";
import DateCell from "@/components_v3/base/table/cells/DateCell.vue";
import StatusCell from "@/components_v3/base/table/cells/StatusCell.vue";
import TextCell from "@/components_v3/base/table/cells/TextCell.vue";
import BooleanCell from "@/components_v3/base/table/cells/BooleanCell.vue";
import NumberCell from "@/components_v3/base/table/cells/NumberCell.vue";

type CellsType = {
    route: 'route';
    number: 'number';
    date: 'date';
    status: 'status';
    text: 'text';
    boolean: 'boolean';
    uniq: 'uniq';
};
type CellType = keyof CellsType;

export type Cell = {
    type: CellType;
    componentData: any;
};

export type FormattedItem<Item> = {
    [key in keyof Item]: Cell;
};

export const CellsComponentsMap = {
    route: RouteCell,
    number: NumberCell,
    date: DateCell,
    status: StatusCell,
    text: TextCell,
    boolean: BooleanCell,
    uniq: 'span',
};
