
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { IBaseAutocomplete, ITableFooterButton, ITableStatus } from "@/types";
import { Affiliate } from "@/services/affiliates/Affiliate";
import ItemTableList from "@/components/base/TableUI/ItemTableList.vue";
import SvgStar from '@/assets/icons/star.svg';
import SvgTrue from '@/assets/icons/true-icon.svg';
import SvgFalse from '@/assets/icons/false-icon.svg';
import { ITableHeader, showNotification, showServerError } from "@/utils";
import { translatePartnerStatus, translateVertical } from "@/utils/translate";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import SvgEdit from "@/assets/icons/pencil.svg";
import SvgCheck from "@/assets/icons/check.svg";
import SvgPlus from "@/assets/icons/plus.svg";
import SvgHash from "@/assets/icons/hashtag.svg";
import { ITag } from "@/api/types/user";
import FormModal from '@/components/base/FormModal.vue';
import {
    bulkAssignTags,
    getAffiliateTags,
    getUsersMasterAccountV3,
    batchAssignManager,
    getAffiliateLoginURL,
    getAffiliateSearch,
    getActiveUserSearch,
    massChangeAffiliatesStatus,
    createOrAddMasterAccountV3,
} from "@/api/user";
import BaseButton from "@/components_v3/base/formComponents/BaseButton.vue";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";
import BaseButtonV2 from "@/components_v3/base/formComponents/buttons/BaseButtonV2.vue";
import CreateTaskModal from "@/components_v3/tasks/CreateTaskModal.vue";
import FooterBulkActions from "@/components_v3/base/table/FooterBulkActions.vue";

@Component({
    methods: { translateVertical },
    components: {
        FooterBulkActions,
        CreateTaskModal,
        BaseButtonV2,
        BaseTooltip,
        FiltersDialog,
        BaseAutocomplete,
        BaseSelect,
        ItemTableList,
        AppTableDesign,
        SvgEdit,
        SvgCheck,
        SvgPlus,
        BaseButton,
        FormModal,
    },
})
export default class AffiliateTable extends Vue {
    @Prop({ required: true }) items!: Affiliate[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isDetailPage!: boolean;

    SvgTrue = SvgTrue;
    SvgFalse = SvgFalse;

    selected: Affiliate[] = [];
    statusSelect: string = '';
    managerSelect: number | string = '';
    masterSelect: number | string = '';
    masterAccountId: number | string = '';
    isLocalLoader: boolean = false;
    isShowFooterDialog: boolean = false;
    isShowCreateTagsModal: boolean = false;
    isShowCreateTasksModal: boolean = false;
    tags: ITag[] = [];
    tagValues: [] = [];

    headers: ITableHeader[] = [
        { text: 'ID пар-ра', sortable: false, value: 'id', align: 'center' },
        { text: 'User ID', sortable: false, value: 'user_id', align: 'center' },
        { text: 'Имя', sortable: false, value: 'full_name', align: 'left', width: 150 },
        { text: 'Никнейм', sortable: false, value: 'nickname', align: 'left' },
        { text: 'Мастер-аккаунт', sortable: false, value: 'master_id', align: 'center', class: '--clear-white-space' },
        { text: 'Имя мастер-аккаунта', sortable: false, value: 'master_account_name', align: 'left', width: 150 },
        { text: 'Почта', sortable: false, value: 'email', align: 'left' },
        { text: 'Статус почты', sortable: false, value: 'email_verified', align: 'center', class: '--clear-white-space' },
        { text: 'Телефон', sortable: false, value: 'phone', align: 'left' },
        {
            text: 'Статус телефона',
            sortable: false,
            value: 'phone_verified',
            align: 'center',
            class: '--clear-white-space',
        },
        { text: 'Аккаунт-менеджер', sortable: false, value: 'manager.full_name', align: 'left', width: 150 },
        { text: 'Вертикаль', sortable: false, value: 'verticals', align: 'left', width: 100 },
        { text: 'Страна', sortable: false, value: 'country', align: 'left' },
        { text: 'Город', sortable: false, value: 'city', align: 'left' },
        { text: 'Язык', sortable: false, value: 'language', align: 'left' },
        { text: 'Новорег', sortable: false, value: 'newcomer', align: 'left' },
        { text: 'Статус', sortable: false, value: 'status', align: 'left' },
        { text: 'Источник трафика', sortable: false, value: 'traffic_sources', align: 'left' },
        { text: 'Реферер', sortable: false, value: 'referer_url', align: 'left' },
        { text: 'Utm source', sortable: false, value: 'utms.utm_source' },
        { text: 'Utm medium', sortable: false, value: 'utms.utm_medium' },
        { text: 'Utm campaign', sortable: false, value: 'utms.utm_campaign' },
        { text: 'Utm term', sortable: false, value: 'utms.utm_term' },
        { text: 'Gid', sortable: false, value: 'utms.gid' },
        { text: 'Aid', sortable: false, value: 'utms.aid' },
        { text: 'Gclid', sortable: false, value: 'utms.gclid' },
        { text: 'Creative', sortable: false, value: 'utms.creative' },
        { text: 'Placement', sortable: false, value: 'utms.placement' },
        { text: 'Rand', sortable: false, value: 'utms.rand' },
        { text: 'Зарегистрирован', sortable: false, value: 'signup_at', align: 'left', width: '120px' },
        { text: 'Создан', sortable: false, value: 'created_at', align: 'left' },
        { text: 'Обновлён', sortable: false, value: 'updated_at', align: 'left' },
        { text: 'Последний логин', sortable: false, value: 'last_login', align: 'left' },
        { text: 'Теги', sortable: false, value: 'tags', align: 'left', width: '120' },
        { text: 'Действия', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
    ];

    actions = [
        {
            title: 'Залогиниться',
            color: '#FFFFFF',
            icon: 'SvgUser',
            isTooltip: true,
            callback: (item: any): void => {
                this.login(item.id);
            },
        },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'affiliate',
            text: 'id',
        },
        {
            routeParam: 'id',
            slot: 'item.user_id',
            routerName: 'affiliate',
            text: 'user_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'master_id',
            slot: 'item.master_id',
            routerName: 'affiliate',
            template: (item: Affiliate): any => {
                return item.is_master === 0 && !item.master_id ? item.id : item.master_id;
            },
            icon: SvgStar,
            showIcon: (item: Affiliate): boolean => {
                return !!item.master_id && item.id !== item.master_id;
            },
            tooltip: (item: Affiliate): string => {
                return !!item.master_id && item.id !== item.master_id ? 'Мастер' : '';
            },
            tooltipClass: 'base-tooltip',
        },
    ];

    timeData = [
        {
            slot: 'item.signup_at',
            key: 'signup_at',
            isLine: false,
        },
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.updated_at',
            key: 'updated_at',
            isLine: false,
        },
        {
            slot: 'item.last_login',
            key: 'last_login.created_at',
            isLine: false,
        },
    ];

    uniqueData = [
        {
            slot: 'item.traffic_sources',
        },
        {
            slot: 'item.referer_url',
        },
        {
            slot: 'item.email_verified',
        },
        {
            slot: 'item.phone_verified',
        },
        {
            slot: 'item.verticals',
        },
        {
            slot: 'item.language',
        },
        {
            slot: 'item.newcomer',
            template: (item: { newcomer: number }): string => item.newcomer ? 'Да' : 'Нет',
        },
        {
            slot: 'item.manager.full_name',
        },
        {
            slot: 'item.full_name',
        },
    ];

    verifiedStatuses = [
        {
            slot: 'item.email_verified',
            value: 'email_verified',
            trueText: 'Подтверждена',
            falseText: 'Не подтверждена',
        },
        {
            slot: 'item.phone_verified',
            value: 'phone_verified',
            trueText: 'Подтверждён',
            falseText: 'Не подтверждён',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePartnerStatus,
        },
    ];

    dropDownItems = [
        {
            slot: 'item.tags',
            text: 'tags',
            readonly: true,
            template: (i: ITag): string => i.name,
        },
    ];

    footerBulkActions = [
        { text: 'Изменить', icon: SvgEdit, action: this.toggleDialog },
        { text: 'Добавить теги', icon: SvgHash, action: this.openTagsModal },
        { text: 'Создать задачи', icon: SvgPlus, action: this.openTasksModal },
    ];

    changeContentOverflow(): void {
        if (this.isSmallTable && this.isDesktop) {
            const container: any = document.querySelector('.base-list-layout__content');
            container.style.overflowY = 'unset';
        }
    }

    async saveTags(): Promise<void> {
        try {
            await bulkAssignTags({
                affiliates: this.getSelectIds as number[],
                tags: this.tagValues,
            });

            showNotification('Теги сохранены');
        } catch (e) {
            showServerError(e, 'Не удалось сохранить теги');
        } finally {
            this.resetSelected();
            this.closeTagsModal();
        }
    }

    closeTagsModal(): void {
        this.isShowCreateTagsModal = false;
        this.tagValues = [];
    }

    async openTagsModal(): Promise<void> {
        try {
            this.tags = await getAffiliateTags();
            this.isShowCreateTagsModal = true;
        } catch (e) {
            showServerError(e, 'Не удалось подгрузить список тегов');
        }
    }

    resetSelected(): void {
        this.selected = [];
    }

    openTasksModal(): void {
        this.isShowCreateTasksModal = true;
    }

    async login(id: number): Promise<void> {
        try {
            const { url } = await getAffiliateLoginURL(id);
            window.open(url, '_blank');
        } catch (err) {
            showServerError(err, 'Не удалось авторизоваться как партнёр');
        }
    }

    get getSelectIds(): number[] {
        return this.selected.map(i => i.id) as number[];
    }

    get getItems(): Affiliate[] {
        return this.items.map(i => ({
            ...i,
            ...{
                master_id: i.master_id !== undefined &&
                i.id !== undefined &&
                i.master_id === null ? i.id : i.master_id,
            },
        })) as Affiliate[];
    }

    get isDesktop(): boolean {
        return this.$vuetify.breakpoint.width >= 769;
    }

    get isShowDesktopFooterActions(): boolean {
        return this.$vuetify.breakpoint.width >= 1264;
    }

    get isSmallTable(): boolean {
        return this.items.length <= 10;
    }

    get getAttach(): string {
        return `.attach-${this.$route.name}`;
    }

    toggleDialog(): void {
        this.isShowFooterDialog = !this.isShowFooterDialog;
    }

    get getManagerData(): IBaseAutocomplete {
        return {
            apiMethod: getActiveUserSearch,
            errorText: 'Менеджер не найден',
            placeholder: 'Менеджер',
            itemValue: 'id',
            template: (i) => `${i.id} ${i.first_name} ${i.last_name}`,
            multiple: false,
            menuProps: { top: this.isDesktop && !this.isSmallTable },
        };
    }

    get getMasterData(): IBaseAutocomplete {
        return {
            apiMethod: getAffiliateSearch,
            errorText: 'Мастер аккаунт не найден',
            placeholder: 'Мастер аккаунт',
            itemValue: 'id',
            template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
            disabled: this.isDisabledMasterSelect,
            isClearIfDisabled: true,
            multiple: false,
            menuProps: { top: this.isDesktop && !this.isSmallTable },
        };
    }

    get isDisabledMasterSelect(): boolean {
        return this.selected.some(i => !(i.master_id === i.id && i.has_slaves === 0));
    }

    @Watch('isDisabledMasterSelect')
    clearMaster(): void {
        if (this.isDisabledMasterSelect && this.masterAccountId !== '') {
            this.masterAccountId = '';
            this.masterSelect = '';
        }
    }

    changeManager(id: number): void {
        this.managerSelect = id;
    }

    async changeMaster(id: number): Promise<void> {
        try {
            this.masterSelect = id;
            const masterAccId = await getUsersMasterAccountV3({ account_id: id });
            const masterID = masterAccId.data.master_id;
            this.masterAccountId = masterID !== null ? masterID : id;
        } catch (err) {
            showServerError(err, 'Мастер аккаунт не определён');
        }
    }

    get isLoading(): boolean {
        return this.loading || this.isLocalLoader;
    }

    get isDisabledFooterButton(): boolean {
        return !([this.statusSelect, this.managerSelect, this.masterSelect].some(i => !!i));
    }

    get footerButtons(): ITableFooterButton[] | undefined {
        if (this.isShowDesktopFooterActions) {
            return [
                {
                    text: 'Изменить',
                    textOfConfirm: 'Подтвердить?',
                    color: 'green',
                    icon: 'check',
                    disabled: this.isDisabledFooterButton,
                    action: this.changeSelected,
                },
            ];
        }
    }

    get statusSelectItems(): { id: string, text: string }[] {
        const statuses = ['active', 'pending', 'deleted', 'blocked', 'rejected'];
        return statuses.map(id => {
            return {
                id,
                text: translatePartnerStatus(id).text,
            };
        });
    }

    async changeSelected(): Promise<void> {
        try {
            this.isLocalLoader = true;
            if (this.statusSelect) {
                const affiliates = this.selected.map(i => i.id!);
                const data = { affiliate_ids: affiliates, status: this.statusSelect };
                await massChangeAffiliatesStatus(data);
            }
            if (this.managerSelect) {
                const affiliate_ids = this.selected.map(i => i.id!);
                const data = { affiliate_ids, manager_id: +this.managerSelect };
                await batchAssignManager(data);
            }
            if (this.masterSelect) {
                const slaves = this.selected.map(i => (i.id!));
                await createOrAddMasterAccountV3({
                    account_id: this.masterAccountId,
                    account_ids: slaves,
                });
            }
            if (this.managerSelect) {
                showNotification('Изменения происходят не сразу. Партнёры добавлены в очередь.');
            } else {
                showNotification('Партнёры успешно обновлены');
            }
        } catch (err) {
            if (err.status === 429) {
                showServerError(err, 'Изменения происходят не сразу. Партнёры добавлены в очередь.');
            } else {
                showServerError(err, 'Партнёры не обновлены');
            }
        } finally {
            this.resetSelected();
            this.isLocalLoader = false;
            this.$emit("update-table");
        }
    }

    get refs(): any {
        return this.$refs;
    }

    get getRoutes(): any {
        return this.isDetailPage ?
            this.routes.map(r => {
                return r.slot === 'item.id' ? {
                    ...r,
                    icon: SvgStar,
                    showIcon: (item: Affiliate): boolean => {
                        return !!item?.is_master!;
                    },
                    tooltip: (item: Affiliate): string => {
                        return !!item?.is_master! ? 'Мастер' : '';
                    },
                    tooltipClass: 'base-tooltip',
                } : r;
            }) : this.routes;
    }

    async copyRefer(id: number): Promise<void> {
        const range = document.createRange();
        range.selectNode(this.refs[`link${id}`] as Element);
        window.getSelection()!.removeAllRanges();
        window.getSelection()!.addRange(range);
        document.execCommand('copy');
        window.getSelection()!.removeAllRanges();
        showNotification('Ссылка скопирована');
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    resetSelect(): void {
        this.statusSelect = '';
        this.managerSelect = '';
        this.masterSelect = '';
    }
}
