import { ISignature } from "@/api/types/support";

const toBoolean = (value: any): boolean => {
    if (value === 'false') return false;
    return !!value;
};

export const API_GATEWAY_URL = '/api';

export const API_ANTIFRAUD_URL = API_GATEWAY_URL + '/antifraud/v1';
export const API_AUTOSTATS_URL = API_GATEWAY_URL + '/autostats/v1';
export const API_CAMPAIGNS_URL = API_GATEWAY_URL + '/campaigns/v1';
export const API_CATALOGUE_URL = API_GATEWAY_URL + '/catalogue/v1';
export const API_CATALOGUE_URL_V2 = API_GATEWAY_URL + '/catalogue/v2';
export const API_CATALOGUE_URL_V3 = API_GATEWAY_URL + '/catalogue/v3';
export const API_CONTEST_URL = API_GATEWAY_URL + '/contest/v2';
export const API_CONVERSION_PROXY = API_GATEWAY_URL + '/conversions-report-proxy/v1/';
export const API_NODE_FILE = API_GATEWAY_URL + '/node-files/v1';
export const API_NOTIFICATIONS_URL = API_GATEWAY_URL + '/notifications/v1';
export const API_OFFERS_URL = API_GATEWAY_URL + '/offers/v1';
export const API_OFFERS_URL_V2 = API_GATEWAY_URL + '/offers/v2';
export const API_ORG_CABINETS_BACKEND_URL = API_GATEWAY_URL + '/org-cabinets/v1';
export const API_PAYOUT_URL = API_GATEWAY_URL + '/payout/v1';
export const API_PAYOUT_URL_V2 = API_GATEWAY_URL + '/payout/v2';
export const API_PAYOUT_URL_V3 = API_GATEWAY_URL + '/payout/v3';
export const API_REGISTRATIONS_URL = API_GATEWAY_URL + '/referrals/v1';
export const API_REVENUE_URL = API_GATEWAY_URL + '/revenue/v1';
export const API_REVENUE_URL_V2 = API_GATEWAY_URL + '/revenue/v2';
export const API_STATS_URL = API_GATEWAY_URL + '/stats/v1';
export const API_SUPPORT_URL = API_GATEWAY_URL + '/support/v1';
export const API_UAPI_URL = API_GATEWAY_URL + '/universal/v1';
export const API_USERS_URL = API_GATEWAY_URL + '/users/v1';
export const API_USERS_URL_2 = API_GATEWAY_URL + '/users/v2';
export const API_USERS_URL_3 = API_GATEWAY_URL + '/users/v3';
export const API_LEADGID_ORD_URL = API_GATEWAY_URL + '/leadgid-ord/v1';
export const API_TASKS_URL = API_GATEWAY_URL + '/tasks/v1';

export enum E_API_URLS {
    ANTIFRAUD = '/antifraud/v1',
    CAMPAIGNS = '/campaigns/v1',
    CATALOGUE = '/catalogue/v1',
    CATALOGUE_V2 = '/catalogue/v2',
    CONTEST = '/contest/v2',
    CONVERSIONS_REPORT = '/conversions-report/v1/',
    CONVERSION = '/conversions-report-proxy/v1/',
    NODE = '/node-files/v1',
    NOTIFICATIONS = '/notifications/v1',
    OFFERS = '/offers/v1',
    OFFERS_V2 = '/offers/v2',
    ORG_CABINETS_BACKEND = '/org-cabinets/v1',
    PAYOUT = '/payout/v1',
    PAYOUT_V2 = '/payout/v2',
    REFERRALS = '/referrals/v1',
    REVENUE = '/revenue/v1',
    REVENUE_V2 = '/revenue/v2',
    STATS = '/stats/v1',
    SUPPORT = '/support/v1',
    UAPI = '/universal/v1',
    USERS = '/users/v1',
    USERS_V2 = '/users/v2',
}

// отображение тоглерки
export const ENV_TOGGLER = toBoolean(process.env.ENV_TOGGLER);

// включить/отключить проверку на DEBUG
export const DEBUG = toBoolean(process.env.DEBUG) || false;

export const STATA_URL = process.env.STATA_URL || 'https://stata.leadgid.ru/';

// пагинация
export const PAGINATION_PER_PAGE = Number(process.env.PAGINATION_PER_PAGE) || 100;
export const PAGINATION_PER_SHORT_TABLE = Number(process.env.PAGINATION_PER_SHORT_TABLE) || 10;
export const PAGINATION_TOTAL_VISIBLE = Number(process.env.PAGINATION_TOTAL_VISIBLE) || 7;
export const PAGINATION_RIGHT_PER_PAGE = Number(process.env.PAGINATION_RIGHT_PER_PAGE) || 10;
export const PAGINATION_RIGHT_50_PER_PAGE = Number(process.env.PAGINATION_RIGHT_50_PER_PAGE) || 50;
// пагинация отчет
export const REPORT_PAGINATION_PER_PAGE = Number(process.env.REPORT_PAGINATION_PER_PAGE) || 100;
export const REPORT_PAGINATION_TOTAL_VISIBLE = Number(process.env.REPORT_PAGINATION_TOTAL_VISIBLE) || 7;

// расширенный отчет, ограничение по дате
export const REPORT_ALLOWED_DATE_GEO = process.env.REPORT_ALLOWED_DATE_GEO || '2022-11-01';
export const REPORT_ALLOWED_DATE_SUBS = process.env.REPORT_ALLOWED_DATE_SUBS || '2022-10-15';

// nav-drawer
export const NAV_DRAWER_MOBILE = Number(process.env.NAV_DRAWER_MOBILE) || 960;
export const NAV_DRAWER_WIDTH = process.env.NAV_DRAWER_WIDTH || '320px';

// задержки
export const SEARCH_VALUE_DEBOUNCE = Number(process.env.SEARCH_VALUE_DEBOUNCE) || 850;
export const CHANGE_ROUTE_DEBOUNCE = Number(process.env.CHANGE_ROUTE_DEBOUNCE) || 1000;
export const SHOW_NOTIFICATION_TIME = Number(process.env.SHOW_NOTIFICATION_TIME) || 5000;

// счета
export const MAX_AMOUNT_INVOICE_DISCREPANCY_RU = Number(process.env.MAX_AMOUNT_INVOICE_DISCREPANCY_RU) || 1000;
export const MAX_AMOUNT_INVOICE_DISCREPANCY_KZT = Number(process.env.MAX_AMOUNT_INVOICE_DISCREPANCY_KZT) || 10000;

// notification
export const DURATION_NOTIFICATION = Number(process.env.DURATION_NOTIFICATION) || 10000;

export const MAX_LIMIT_FOR_SELECT = Number(process.env.MAX_LIMIT_FOR_SELECT) || 900;

export const CONTEST_ID = process.env.CONTEST_ID || '2';
export const CONTEST_GROWING_POINT = process.env.CONTEST_GROWING_POINT || '3';

// dadata
export const TOKEN = process.env.DADATA_TOKEN || '048dadf5d41cf9ab423264ad914baecd1893540c';

// разрешенное отклонение разницы: взаимозачет - сумма счета
export const ALLOWED_DIFFERENCE_NETTING = Number(process.env.ALLOWED_DIFFERENCE_NETTING) || 10;

// максимальное колличество топов по офферам за неделю
export const MAX_LIMIT_OFFERS = Number(process.env.MAX_LIMIT_OFFERS) || 10;

// campaigns
export const CASHBACK_ECOM = Number(process.env.CASHBACK_ECOM) || 6;
export const CURRENT_CAMPAIGNS = Number(process.env.CURRENT_CAMPAIGNS) || 9;

export const SUPPORT_SIGNATURE: ISignature = {
    text_ru: "Спасибо за обращение. \nС уважением, команда Leadgid",
    text_en: "Thank you for reaching out. \nBest regards, Leadgid Team",
};
