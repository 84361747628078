import { defineStore } from "pinia";
import { ref } from "vue";

export const useRegistrationsStore = defineStore('useRegistrationsStore', () => {
    const isNeedUpdateRegistrations = ref<boolean>(false);
    const setUpdateRegistrationsFlag = (value: boolean) => {
        isNeedUpdateRegistrations.value = value;
    };

    return {
        isNeedUpdateRegistrations,
        setUpdateRegistrationsFlag,
    };
});
